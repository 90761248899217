.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* user added */

.app-container {
  padding: 30px;
  /*background-color: red;*/
  height: 100%; margin: 0;
}

.app-title {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}

.button {
  border-radius: 10px;
  background-color: black;
  border-width: 0;
  color: white;
  outline: 0;
  font-size: 14pt;
  font-weight: bold;
  height: 50px;
  width: 100%;
}

.button:active {
  background-color: blue;
}

.button:hover {
  background-color: black;
  opacity: 0.6;
}

.modal {
  box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  outline: none;
  border-radius: 20px 20px 0 0;
  padding: 24px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-header {
  font-size: 20px;
  margin: 0;
  font-weight: bolder;
}

.email-input {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
  outline: none;
  border-radius: 8px;
  border-style: none;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14pt;
  padding-left: 16px;
  font-weight: bold;
  margin-bottom: 32px;
  margin-top: 32px;
}

/* Modal Animation*/
.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(100%);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(100%);
}

/* make list numbers bold */
ol > li::marker {
  font-weight: bold;
}
